<template>
  <el-card class="box-card"
           style="margin-top:20px;">
    <div slot="header"
         class="clearfix">
      <span class="title">平台公告</span>
    </div>
    <ul style="min-height:260px;">
      <el-scrollbar style="height: 100%;">
        <li v-for="item in noticeList"
            :key="item.announce_id"
            @click="noticeClick(item.announce_id)"
            class="text item">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="contentWrap">
                <span :title="item.title"
                      ref="noticeP"
                      class="content">{{item.title}}</span>
                <span v-show="item.top"
                      class="icon iconfont icon-zhiding"></span>
              </div>
            </el-col>
            <el-col :span="3">
              <span class="time">{{$moment(item.updated_at).format('MM-DD')}}</span>
            </el-col>
          </el-row>
        </li>
      </el-scrollbar>
    </ul>
  </el-card>

</template>

<script>
import api from "@/api/index";
export default {
  data () {
    return {
      noticeList: []
    }
  },
  created () {
    this._initState()
  },
  methods: {
    async _initState () {
      const { status_code, message } = await api.announceApi({ type: 0 })
      if (status_code === 200) {
        this.noticeList = message
      }
    },
    noticeClick (id) {
      this.$router.push({ path: '/home/notice/' + id })
    }
  }
}
</script>

<style lang="less" scoped>
.contentWrap {
  display: flex;
}
.time {
  font-size: 14px;
  color: #999999;
  display: inline-block;
  min-width: 36px;
}
.title {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
}
li {
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: red;
    position: relative;
    top: 11px;
  }
}
li + li {
  margin-top: 20px;
}
.contentWrap {
  width: 100%;
}
.content {
  font-size: 14px;
  color: #333333;
  padding-left: 10px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    color: #ff2c38;
  }
}
.icon-zhiding {
  color: #ff2c38;
  margin-top: 2px;
}
ul {
  height: 175px;
}
//滚动条
.box-card /deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
.box-card /deep/ .el-scrollbar__bar.is-horizontal {
  display: none;
}
.box-card /deep/ .el-card__body {
  padding: 20px 0 20px 20px;
}
</style>