<template>
  <div class="wdc">
    <ul v-loading="loading">
      <li v-for="item in itemList"
          :key="item.text">
        <div class="item-wrap">
          <div class="left">
            <span class="left-text">{{item.text}}</span>
            <h6>
              <span class="strong">{{item.price}}</span>
              <span class="price">元</span>
            </h6>
          </div>
          <span :class="`icon iconfont ${item.icon}`"></span>
        </div>
        <img src="../../static/img/wave.png"
             alt="波浪" />
      </li>
    </ul>
    <div class="account">
      <div>
        <p class="cus_p">支付宝账户：{{`${alipay_account ? alipay_account : '无'}(${userName})`}}</p>
        <el-tooltip class="item"
                    effect="dark"
                    :content="content"
                    placement="top">
          <i class="cus_icon icon iconfont icon-wenhao"></i>
        </el-tooltip>
      </div>
      <el-button type="primary"
                 @click="withdrawClick"
                 class="el-btn">提现</el-button>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import { mapState } from "vuex";
export default {
  data () {
    return {
      loading: true,
      alipay_account: "",
      userName: "",
      itemList: [
        {
          text: "本月收益余额",
          price: "0.00",
          icon: "icon-benyue",
        },
        {
          text: "上月收益余额",
          price: "0.00",
          icon: "icon-qianbao",
        },
        {
          text: "钱包余额",
          price: "0.00",
          icon: "icon-shangyue",
        },
      ],
      content: '',
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created () {
    this._initState();
    if (this.userInfo.role === 'personal') {
      this.content = "您可以点击左侧菜单前往个人中心进行认证"
    } else {
      this.content = "该账号为您进行扫脸实名认证时的唯一账号"
    }
  },
  methods: {
    async _initState () {
      const { status_code, message } = await api.fortuneApi();
      if (status_code === 200) {
        this.itemList[0].price = message.coins;
        this.itemList[1].price = message.diamond;
        this.itemList[2].price = message.history;
        this.alipay_account = message.alipay_account;
        this.userName = message.name;
        this.loading = false;
      }
    },
    withdrawClick () {
      this.$router.push({
        name: "withdrawDetail",
        params: { alipay_account: this.alipay_account, name: this.userName },
      });
    },
  },
};
</script>

<style lang="less" scoped>
li {
  width: 100%;
  height: 120px;
  border-radius: 4px;
  position: relative;
}
li:nth-child(1) {
  background: linear-gradient(
    90deg,
    rgba(255, 138, 22, 1) 0%,
    rgba(249, 203, 64, 1) 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
    0px 2px 6px 0px rgba(249, 203, 64, 0.4);
}
li:nth-child(2) {
  margin: 0 10px;
  background: linear-gradient(
    90deg,
    rgba(58, 149, 244, 1) 0%,
    rgba(69, 197, 236, 1) 100%
  );

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
    0px 2px 6px 0px rgba(69, 197, 236, 0.4);
}
li:nth-child(3) {
  background: linear-gradient(
    90deg,
    rgba(61, 188, 170, 1) 0%,
    rgba(61, 224, 168, 1) 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
    0px 2px 6px 0px rgba(61, 224, 168, 0.4);
}
ul {
  display: flex;
  justify-content: space-around;
}
.left {
  margin-top: 26px;
  margin-left: 20px;
}
.left-text {
  font-size: 14px;
  color: #fff;
}
.account {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  p {
    font-size: 14px;
    color: #666666;
  }
}
.strong,
.price {
  color: #fff;
}
.strong {
  font-size: 28px;
  font-weight: 400;
}
.price {
  font-size: 14px;
}
h6 {
  margin-top: 20px;
}
img {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.item-wrap {
  display: flex;
  justify-content: space-between;
}
.iconfont {
  display: block;
  margin-right: 20px;
  margin-top: 24px;
  font-size: 33px;
  color: #fff;
}
.cus_p {
  display: inline-block;
}
.cus_icon.iconfont {
  display: inline-block;
  margin-left: 4px;
  margin-right: 0px;
  margin-top: 0px;
  font-size: 14px;
  color: #666666;
  flex-shrink: 0;
  vertical-align: -1px;
}
</style>