<template>
  <div>
    <el-card class="card">
      <div slot="header" class="clearfix">
        <span class="title">常用功能</span>
      </div>
      <div>
        <ul>
          <li v-for="item in iconList" :key="item.title" @click="commonClick(item.path)">
            <img :src="item.imgUrl" alt="常用功能图标" />
            <span class="icon-text">{{item.title}}</span>
          </li>
        </ul>
      </div>
    </el-card>
     <div class="notice-main-style">
      <el-dialog :visible.sync="showNotice"
                 width="500px"
                 top="10vh"
                 :show-close="false">
        <div slot="title"
             style="height:58px;line-height:58px;text-align:center;font-weight:700;">经纪人注意事项</div>
        <div v-html="notice"
             class="notice-style"></div>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="clickNoticeOk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import notice from "../../../utils/notice";
export default {
  data() {
    return {
      notice,
      showNotice: false,
      iconList: [
        {
          title: "主播录入",
          imgUrl: require("img/anchor_input.png"),
          path: "/anchor/anchorAdd",
        },
        {
          title: "审核经纪人",
          imgUrl: require("img/audit_agent.png"),
          path: "/agentmanage/check",
        },
        {
          title: "经营分析",
          imgUrl: require("img/manage_analyze.png"),
          path: "/finance/operation",
        },
        {
          title: "联系我们",
          imgUrl: require("img/contact_us.png"),
          path: "/home/contactus",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    commonClick(path) {
      if (this.userInfo.role == "personal" && path == "/anchor/anchorAdd") {
        this.$message.warning("您没有此权限，请联系管理员");
      } else {
        if(path == "/anchor/anchorAdd"){
          this.showNotice = true
        }else{
          this.$router.push({ path });
        }
        
      }
    },
    clickNoticeOk(){
      this.showNotice = false;
      this.$router.push({ path: "/anchor/anchorAdd" });
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
li {
  text-align: center;
  width: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
li:nth-child(3),
li:nth-child(4) {
  margin-top: 36px;
}
img {
  display: block;
  width: 45px;
  height: 45px;
  margin-bottom: 11px;
  &:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
  }
}

.icon-text {
  font-size: 14px;
  color: #333333;
}
.notice-style {
  height: 400px;
  line-height: 40px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.notice-main-style /deep/ .el-dialog__body {
  padding: 0 20px !important;
}
.notice-main-style /deep/ .el-dialog__header {
  padding: 0 !important;
}
.notice-style::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>