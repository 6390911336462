<template>
  <div class="homeWrap">
    <el-row :gutter="20">
      <el-col :span="18">
        <withdraw></withdraw>
      </el-col>
      <el-col :span="6">
        <common-func></common-func>
      </el-col>
    </el-row>
    <core-data></core-data>
    <el-row :gutter="20">
      <el-col :span="8">
        <announcement></announcement>
      </el-col>
      <el-col :span="16">
        <chart></chart>
        <!-- <policy></policy> -->
      </el-col>
    </el-row>
    <el-row>
      <seniority></seniority>
    </el-row>
    <emergency-notice />
  </div>
</template>

<script>
import commonFunc from './components/commonFunc'
import withdraw from './components/withdraw'
import coreData from './components/coreData'
import announcement from './components/announcement'
// import policy from './components/policy'
import chart from './components/chart'
import seniority from './components/seniority'
import emergencyNotice from "./components/emergencyNotice"
export default {
  name: 'home',
  components: {
    commonFunc,
    withdraw,
    coreData,
    announcement,
    // policy,
    chart,
    seniority,
    emergencyNotice
  }
}
</script>

<style lang="less" scoped>
</style>